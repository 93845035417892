import React from "react"
import colors from "constants/colors"
import { Text } from "@chakra-ui/react"
import Layout from "@components/Layout/hoc/withCmsData"
import Helmet from "@components/Helmet"
import { bp } from "@utils/MediaQueries"

const NotFound: any = () => {
  return (
    <Layout>
      <Helmet title="SKB Cases" />
      <Text
        as="h1"
        w="100%"
        margin={bp("68px 0", null, "160px 0")}
        fontFamily="Avenir"
        fontSize={bp("33px", null, "48px")}
        fontWeight="800"
        textAlign="center"
        color={colors.dark}
      >
        404: Oops, this page doesn't exist.
      </Text>
    </Layout>
  )
}

export default NotFound
